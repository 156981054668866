<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <p class="has-text-centered" id="title-dashboard">
        Bienvenido <strong>¿Qué deseas hacer hoy?</strong>
      </p>
      <div class="columns is-multiline is-centered">
        <div class="column is-12" v-if="loading">
          <loading />
        </div>
        <div class="column is-3" v-if="customerMenu">
          <div class="card">
            <div class="card-content">
              <div class="content-principal">
                <img src="@/assets/clientes.svg"/>
                <h1 class="titleCard">Clientes</h1>
                <p class="description">Crea y gestiona el expediente de tu cartera de clientes</p>
              </div>
              <a :href="getLinkLocal({ section: 'general', path: customerMenu.path })" class="link">
                Ver más
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="column is-3" v-if="applicationMenu">
          <div class="card">
            <div class="card-content">
              <div class="content-principal">
                <img src="@/assets/solicitudes.svg"/>
                <h1 class="titleCard">Solicitudes</h1>
                <p class="description">Revisa, gestiona, analiza, otorga o rechaza las solicitudes de crédito</p>
              </div>
              <a :href="getLinkLocal({ section: 'analyst', path: applicationMenu.path })" class="link">
                Ver más
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="column is-3" v-if="contractMenu">
          <div class="card">
            <div class="card-content">
              <div class="content-principal">
                <img src="@/assets/creditos.svg"/>
                <h1 class="titleCard">Créditos</h1>
                <p class="description">Observa el comportamiento de tu cartera de créditos otorgados</p>
              </div>
              <a :href="getLinkLocal({ section: 'core', path: contractMenu.path })" class="link">
                Ver más
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="column is-3" v-if="buzonPldMenu">
          <div class="card">
            <div class="card-content">
              <div class="content-principal">
                <img src="@/assets/buzon-pld.svg"/>
                <h1 class="titleCard">Buzón PLD</h1>
                <p class="description">Registra tus observaciones de comportamientos anormales</p>
              </div>
              <a :href="getLinkLocal({ section: 'pld', path: buzonPldMenu.path })" class="link">
                Ver más
                <i class="fa fa-angle-right" aria-hidden="true"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  components: {
    Loading: () => import('@/components/ui/Loading.vue')
  },
  data () {
    return {
      loading: true,
      menu: []

    }
  },
  methods: {
    ...mapActions(['getMenu']),
    getLinkLocal ({ section, path }) {
      if (section === 'external') {
        return path
      }
      const baseURL = `${process.env.VUE_APP_PROTOCOL}://${window.location.hostname}`
      const linkPath = path ? `${process.env[`VUE_APP_${section.toUpperCase()}`]}/${path}`.replace('//', '/') : ''
      return `${baseURL}${linkPath}`
    }
  },
  computed: {
    applicationMenu () {
      if (this.menu) {
        const menuSelected = this.menu.reduce((accum, category) => {
          const itemList = category.subcategories.reduce((accumItem, el) => (accumItem = [...accumItem, ...el.items]), [])
          return [...accum, ...itemList]
        }, []).find(item => item.name === 'Solicitudes')
        return menuSelected
      }
      return null
    },
    customerMenu () {
      if (this.menu) {
        const menuSelected = this.menu.reduce((accum, category) => {
          const itemList = category.subcategories.reduce((accumItem, el) => (accumItem = [...accumItem, ...el.items]), [])
          return [...accum, ...itemList]
        }, []).find(item => item.name === 'Clientes')
        return menuSelected
      }
      return null
    },
    contractMenu () {
      if (this.menu) {
        const menuSelected = this.menu.reduce((accum, category) => {
          const itemList = category.subcategories.reduce((accumItem, el) => (accumItem = [...accumItem, ...el.items]), [])
          return [...accum, ...itemList]
        }, []).find(item => item.name === 'Créditos')
        return menuSelected
      }
      return null
    },
    buzonPldMenu () {
      if (this.menu) {
        const menuSelected = this.menu.reduce((accum, category) => {
          const itemList = category.subcategories.reduce((accumItem, el) => (accumItem = [...accumItem, ...el.items]), [])
          return [...accum, ...itemList]
        }, []).find(item => item.name === 'Buzón PLD')
        return menuSelected
      }
      return null
    }
  },
  created () {
    this.getMenu()
      .then(resp => {
        this.menu = resp.categories
        this.loading = false
      })
  }

}
</script>

<style lang="scss" scoped>
.content-image {
  text-align: left;
  width: 100%;
  img {
    width: 300px;
  }
}

.is-fullheight {
  min-height: calc(100vh - 66px);
}
.hero-body {
  display: flex;
  flex-direction: column;
  align-items: inherit !important;
}
p {
  font-size: 16px;
  font-weight: 500;
  color: #2e3e50;
}

.card {
  margin-top: 1.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  border-radius: 10px;
  box-shadow: 0 6px 30px 0 rgba(0, 0, 0, 0.1);

}
.card-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 400px;
  padding: 0.5 1rem;
  img {
    width: 150px;
    height: 140px;
  }
  .content-principal {
    padding-top: 1rem;
    text-align: center;
  }
  .titleCard {
    font-size: 20px;
    font-weight: 700;
    color: #283056;
    text-align: center;
  }
  .description {
    font-size: 12px;
    color: #888888;
    text-align: center;
    margin-top: 1rem;
  }
  .link {
    font-size: 14px;
    color: #5174ff;
    text-align: right;
    width: 100%;
    font-weight: 500;
    padding-right: 0.5rem;
  }
}
#title-dashboard {
  margin-bottom: 0.5rem;
}
</style>
